import Hero from "../../components/Hero/Hero";

function Home() {
    //définition du usestate (variable dynamique)

    //comportements (fonctions dynamiques)

    //rendu (transforme un composant en HTML)
    return (
        <div className="">
            <Hero />
        </div>
    );
}

export default Home;